import { Component, OnInit } from '@angular/core';
import { ActionState } from 'app/config/action-state';
import { FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';

@Component({
  selector: 'menu-external-content-manager',
  templateUrl: './menu-external-content-manager.component.html',
  styleUrls: ['./menu-external-content-manager.component.scss']
})
export class MenuExternalContentManagerComponent implements OnInit {
  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;

  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;
  /**
   * action states for module 1
   */
  actionState: ActionState = new ActionState('', true, true, true, true, true, true, true, false, false);

  constructor(private menuActionService: MenuActionService, private dataService: DataService) {}

  ngOnInit(): void {}

  /**
   * execute action for Save in menu
   *
   * @param {string} moduleName current module name
   */
  save(moduleName: string) {
    this.menuActionService.saveContentDetail(moduleName);
  }

  createOutputFile(moduleName: string) {
    this.menuActionService.createOutputFile(moduleName);
  }

  /**
   * execute action for Add New in menu
   *
   * @param {string} moduleName current module name
   */
  add(moduleName: string) {
    this.menuActionService.add(moduleName);
  }

  /**
   * execute action for Edit in menu
   *
   * @param {string} moduleName current module name
   */
  edit(moduleName: string) {
    this.menuActionService.edit(moduleName);
  }

  /**
   * execute action for Delete in menu
   *
   * @param {string} moduleName current module name
   */
  delete(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * execute action for Change Template in menu
   *
   * @param {string} moduleName current module name
   */
  changeTemplate(moduleName: string) {
    this.menuActionService.changeTemplate(moduleName);
  }

  /**
   * execute action for Undo in menu
   *
   * @param {string} moduleName current module name
   */
  undo(moduleName: string) {
    this.menuActionService.undo(moduleName);
  }

  /**
   * execute action for Redo in menu
   *
   * @param {string} moduleName current module name
   */
  redo(moduleName: string) {
    this.menuActionService.redo(moduleName);
  }
}
