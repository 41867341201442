import { Component, OnInit } from '@angular/core';
import { ActionState } from 'app/config/action-state';
import { FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';

@Component({
  selector: 'menu-simple-signage-editor',
  templateUrl: './menu-simple-signage-editor.component.html',
  styleUrls: ['./menu-simple-signage-editor.component.scss']
})
export class MenuSimpleSignageEditorComponent implements OnInit {
  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;
  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;
  /**
   * true if choose tab playlist
   */
  isDisabled: boolean;
  /**
   * true if choose tab playlist
   */
  isDisabledOption: boolean;
  /**
   * true if edit playlist
   */
  isEditPlaylist: boolean;
  /**
   * true if edit folder
   */
  isEditFolder: boolean;
  /**
   * true if media of sequence is selected
   */
  isSelectedMediaOfSequence: boolean;
  /**
   * action states for module 1
   */
  actionState1: ActionState = new ActionState('', true, true, true, true, true, true, true, false, false);
  /**
   * true if checked expiration
   */
  isCheckedExpiration: boolean;

  /**
   * true if checked playback time
   */
  isCheckedPlaybackTime: boolean;

  constructor(private menuActionService: MenuActionService, private dataService: DataService, private commonService: CommonService) {
    this.isCheckedExpiration = commonService.getCommonObject().isCheckedExpiration;
    this.isCheckedPlaybackTime = commonService.getCommonObject().isCheckedPlaybackTime;
    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'isChosenTabPlaylist') {
        this.isDisabled = <boolean>data[1];
      }
      if (data[0] == 'isEditPlaylist') {
        this.isEditPlaylist = <boolean>data[1];
      }
      if (data[0] == 'isEditFolder') {
        this.isEditFolder = <boolean>data[1];
      }
      if (data[0] == 'isOnPreview') {
        this.isDisabledOption = <boolean>data[1];
      }
      if (data[0] == 'isDeleteDisable') {
        this.isSelectedMediaOfSequence = <boolean>data[1];
      }
    });
  }

  ngOnInit(): void {}

  /**
   * execute action for import media in menu
   * @param {string} moduleName current module name
   */
  importMedia(moduleName: string) {
    this.menuActionService.importMedia(moduleName);
  }

  /**
   * execute action for Add playlist in menu
   * @param {string} moduleName current module name
   */
  addPlaylist(moduleName: string) {
    this.menuActionService.add(moduleName);
  }

  /**
   * execute action for Edit playlist in menu
   * @param {string} moduleName current module name
   */
  editPlaylist(moduleName: string) {
    this.menuActionService.edit(moduleName);
  }

  /**
   * execute action for delete playlist in menu
   * @param {string} moduleName current module name
   */
  deletePlaylist(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * execute action for Duplicate playlist in menu
   * @param {string} moduleName current module name
   */
  duplicatePlaylist(moduleName: string) {
    this.menuActionService.duplicate(moduleName);
  }

  /**
   * execute action for Add folder in menu
   * @param {string} moduleName current module name
   */
  addFolderMedia(moduleName: string) {
    this.menuActionService.addFolder(moduleName);
  }

  /**
   * execute action for Edit folder in menu
   * @param {string} moduleName current module name
   */
  editFolderMedia(moduleName: string) {
    this.menuActionService.editFolder(moduleName);
  }

  /**
   * execute action for Delete Item in menu
   * @param {string} moduleName current module name
   */
  deleteItem(moduleName: string) {
    this.menuActionService.deleteItem(moduleName);
  }

  /**
   * execute action for Undo in menu
   * @param {string} moduleName current module name
   */
  undo(moduleName: string) {
    this.menuActionService.undo(moduleName);
  }

  /**
   * execute action for Redo in menu
   * @param {string} moduleName current module name
   */
  redo(moduleName: string) {
    this.menuActionService.redo(moduleName);
  }

  /**
   * execute action for delivery
   * @param {string} moduleName module name
   */
  delivery(moduleName: string) {
    this.menuActionService.delivery(moduleName);
  }

  /**
   * execute action for sync setting
   * @param {string} moduleName module name
   */
  simpleSyncSetting(moduleName: string) {
    this.menuActionService.simpleSyncSetting(moduleName);
  }

  /**
   * execute action for change expiration in menu
   * @param {string} moduleName module name
   */
  changeExpiration(moduleName: string) {
    this.isCheckedExpiration = !this.isCheckedExpiration;
    this.menuActionService.changeExpiration(moduleName);
  }

  /**
   * execute action for change playback time in menu
   * @param {string} moduleName module name
   */
  changePlaybackTime(moduleName: string) {
    this.isCheckedPlaybackTime = !this.isCheckedPlaybackTime;
    this.menuActionService.changePlaybackTime(moduleName);
  }

  /**
   * stop propagation expiration
   * @param e MouseEvent
   */
  stopPropagationExpiration(e: MouseEvent) {
    e.stopPropagation();
  }

  /**
   * stop propagation playback time
   * @param e MouseEvent
   */
  stopPropagationPlaybackTime(e: MouseEvent) {
    e.stopPropagation();
  }
}
