import { Component, OnInit } from '@angular/core';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';

@Component({
  selector: 'menu-schedule-operation-manager',
  templateUrl: './menu-schedule-operation-manager.component.html',
  styleUrls: ['./menu-schedule-operation-manager.component.scss']
})
export class MenuScheduleOperationManagerComponent implements OnInit {
  /**
   * MODULE_NAME
   */
  public MODULE_NAME = MODULE_NAME;
  /**
   * FIELD_COMPONENT
   */
  public FIELD_COMPONENT = FIELD_COMPONENT;
  /**
   * isCheckWidthMenu
   */
  public isCheckWidthMenu: boolean;
  /**
   * isCheckMessage
   */
  public isCheckMessage: boolean;
  /**
   * isCheckEmergency
   */
  public isCheckEmergency: boolean;
  /**
   * isCheckUpdateData
   */
  public isCheckUpdateData: boolean;
  /**
   * isCheckNetWork
   */
  public isCheckNetWork: boolean;
  /**
   * true if network ok
   */
  public isNetWorkOk: boolean;

  public isEditedRow: boolean;
  public enableEdit: boolean;
  public enableUndelete: boolean;
  public disableDelete: boolean;

  public isEditDynamicMessageMode: boolean;
  public isEditEmergencyMode: boolean;
  public isDisabled: boolean;

  constructor(private menuActionService: MenuActionService, private dataService: DataService) {
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_NETWORK_OK) {
        this.isNetWorkOk = <boolean>data[1];
      }
      if (data[0] == Constant.IS_EDIT_ROW) {
        this.isEditedRow = <boolean>data[1];
      }
      if (data[0] == 'enableEdit') {
        this.enableEdit = <boolean>data[1];
        if (data[1] && data[1] == 'undelete') {
          this.enableUndelete = true;
        } else {
          this.enableUndelete = false;
        }
        if (data[1] && data[1] == 'disableDelete') {
          this.disableDelete = true;
        } else {
          this.disableDelete = false;
        }
      }
      if (data[0] == `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:isEditDynamicMessageMode`) {
        this.isEditDynamicMessageMode = <boolean>data[1];
      }
      if (data[0] == `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:isEditEmergencyMode`) {
        this.isEditEmergencyMode = <boolean>data[1];
      }
      this.isDisabled = this.isEditDynamicMessageMode || this.isEditEmergencyMode;
    });
  }

  ngOnInit(): void {
    this.handlingDisplayMenu();
  }

  /**
   * open dynamic message
   * @param moduleName
   */
  public openDynamicMessage(moduleName: string) {
    this.menuActionService.openDynamicMessage(moduleName);
  }

  /**
   * open emergency
   *
   * @param moduleName
   */
  public openEmergency(moduleName: string) {
    this.menuActionService.emergency(moduleName);
  }

  public updateData(moduleName: string) {
    if (this.isEditedRow) {
      return;
    }
    this.menuActionService.updateData(moduleName);
  }

  /**
   * execute action for insert row in menu
   *
   * @param {string} moduleName current module name
   */
  public insertRow(moduleName: string) {
    this.menuActionService.insertRow(moduleName);
  }

  /**
   * execute action for duplicate row in menu
   *
   * @param {string} moduleName current module name
   */
  public duplicateRow(moduleName: string) {
    this.menuActionService.duplicateRow(moduleName);
  }

  /**
   * execute action for edit row in menu
   *
   * @param {string} moduleName current module name
   */
  public editRow(moduleName: string) {
    this.menuActionService.editRow(moduleName);
  }

  /**
   * execute action for duplicate row in menu
   *
   * @param {string} moduleName current module name
   */
  public deleteRow(moduleName: string) {
    this.menuActionService.deleteRow(moduleName);
  }

  /**
   * execute action for show/hidden item in menu
   *
   * @param {string} moduleName current module name
   */
  public showHiddenItemSetting(moduleName: string) {
    this.menuActionService.showHiddenItemSetting(moduleName);
  }

  /**
   * handle  menu  when resize screen
   */
  private handlingDisplayMenu(): void {
    this.isCheckWidthMenu = true;
    this.isCheckMessage = true;
    this.isCheckEmergency = true;
    this.isCheckUpdateData = true;
    this.isCheckNetWork = true;
    this.dataService.currentData.subscribe(data => {
      if (data[0] == `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:checkMessage`) {
        this.isCheckMessage = <boolean>data[1];
      }
      if (data[0] == `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:checkEmergency`) {
        this.isCheckEmergency = <boolean>data[1];
      }
      if (data[0] == `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:checkUpdateData`) {
        this.isCheckUpdateData = <boolean>data[1];
      }
      if (data[0] == `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:checkNetWork`) {
        this.isCheckNetWork = <boolean>data[1];
      }
      this.isCheckWidthMenu = this.isCheckEmergency && this.isCheckUpdateData && this.isCheckNetWork;
    });
  }
}
