import { Component, OnInit } from '@angular/core';
import { ActionState } from 'app/config/action-state';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';

@Component({
  selector: 'menu-timetable-editor',
  templateUrl: './menu-timetable-editor.component.html',
  styleUrls: ['./menu-timetable-editor.component.scss']
})
export class MenuTimetableEditorComponent implements OnInit {
  FIELD_COMPONENT = FIELD_COMPONENT;
  MODULE_NAME = MODULE_NAME;
  isChecked: boolean;
  isCheckWidthMenu: boolean;
  isCheckDisplay: boolean;
  isCheckSetting: boolean;
  isCheckDelivery: boolean;
  isEditingTimetable: boolean;
  isPreviewTimetable: boolean;
  isShowFreeArea: boolean;
  isDisabledButtonSort: boolean;
  isClearField: boolean;
  isChosenTabCalendar: boolean;
  isRecordsTimetableMax: boolean;
  isNotUserRoot: boolean;

  /**
   * action states for module 1
   */
  actionState1: ActionState = new ActionState('', true, true, true, true, true, true, true, false, false);

  constructor(private menuActionService: MenuActionService, private dataService: DataService, private commonService: CommonService) {
    this.isChecked = commonService.getCommonObject().isDisplay2Timetable;
    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'isEditingTimetable') {
        this.isEditingTimetable = <boolean>data[1];
      }
      if (data[0] == 'isPreviewTimetable') {
        this.isPreviewTimetable = <boolean>data[1];
      }
      if (data[0] == 'isChosenTabCalendar') {
        this.isChosenTabCalendar = <boolean>data[1];
      }
      if (data[0] == 'isShowFreeArea') {
        this.isShowFreeArea = <boolean>data[1];
      }
      if (data[0] == 'isDisabledButtonSort') {
        this.isDisabledButtonSort = <boolean>data[1];
      }
      if (data[0] == 'isClearField') {
        this.isClearField = <boolean>data[1];
      }
      if (data[0] == Constant.IS_RECORDS_TIMETABLE_MAX) {
        this.isRecordsTimetableMax = <boolean>data[1];
      }
      if (data[0] == Constant.IS_NOT_USER_ROOT) {
        this.isNotUserRoot = <boolean>data[1];
      }
    });
  }

  ngOnInit(): void {
    this.handlingDisplayMenu();
  }

  /**
   * execute action for import timetable in menu
   * @param {string} moduleName current module name
   */
  importTimetable(moduleName: string) {
    this.menuActionService.importTimetable(moduleName);
  }

  /**
   * execute action for export timetable in menu
   * @param {string} moduleName current module name
   */
  exportTimetable(moduleName: string) {
    this.menuActionService.exportTimetable(moduleName);
  }

  /**
   * execute action for export calendar in menu
   * @param {string} moduleName current module name
   */
  exportCalendar(moduleName: string) {
    this.menuActionService.exportCalendar(moduleName);
  }

  /**
   * execute action for Add in menu
   * @param {string} moduleName current module name
   */
  add(moduleName: string) {
    this.menuActionService.add(moduleName);
  }

  /**
   * execute action for Edit in menu
   * @param {string} moduleName current module name
   */
  edit(moduleName: string) {
    this.menuActionService.edit(moduleName);
  }

  /**
   * execute action for delete in menu
   * @param {string} moduleName current module name
   */
  deleteTimetable(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * execute action for Duplicate in menu
   * @param {string} moduleName current module name
   */
  duplicate(moduleName: string) {
    this.menuActionService.duplicate(moduleName);
  }

  /**
   * execute action for change Template in menu
   * @param {string} moduleName current module name
   */
  changeTemplate(moduleName: string) {
    this.menuActionService.changeTemplate(moduleName);
  }

  /**
   * execute action for set free area in menu
   * @param {string} moduleName current module name
   */
  setFreeArea(moduleName: string) {
    this.menuActionService.setFreeArea(moduleName);
  }

  /**
   * execute action for clear field free area in menu
   * @param {string} moduleName current module name
   */
  clearField(moduleName: string) {
    this.menuActionService.clearField(moduleName);
  }

  /**
   * execute action for edit item name in menu
   * @param {string} moduleName current module name
   */
  editItemName(moduleName: string) {
    this.menuActionService.editItemName(moduleName);
  }

  /**
   * toggle sort and filter item
   * @param {string} moduleName module name
   */
  toggleSortAndFilter(moduleName: string) {
    this.menuActionService.toggleSortAndFilter(moduleName);
  }

  /**
   * change label item
   * @param {string} moduleName current module name
   */
  changeLabel(moduleName: string) {
    this.menuActionService.changeLabel(moduleName);
  }

  /**
   * manage label
   * @param {string} moduleName module name
   */
  manageLabel(moduleName: string) {
    this.menuActionService.manageLabel(moduleName);
  }

  /**
   * reference setting
   * @param {string} moduleName module name
   */
  referenceSetting(moduleName: string) {
    this.menuActionService.referenceSetting(moduleName);
  }

  /**
   * execute action for Undo in menu
   * @param {string} moduleName current module name
   */
  undo(moduleName: string) {
    this.menuActionService.undo(moduleName);
  }

  /**
   * execute action for Redo in menu
   * @param {string} moduleName current module name
   */
  redo(moduleName: string) {
    this.menuActionService.redo(moduleName);
  }

  /**
   * delivery
   * @param {string} moduleName module name
   */
  delivery(moduleName: string) {
    this.menuActionService.delivery(moduleName);
  }

  /**
   * stop propagation
   * @param e MouseEvent
   */
  displayTemplate(e: MouseEvent) {
    e.stopPropagation();
  }

  /**
   * execute action for Change Display in menu
   * @param {string} moduleName current module name
   */
  changeDisplay(moduleName: string) {
    this.isChecked = !this.isChecked;
    this.menuActionService.changeDisplay(moduleName);
  }

  /**
   * execute action for Update Timing in menu
   * @param {string} moduleName current module name
   */
  updateTime(moduleName: string) {
    this.menuActionService.updateTime(moduleName);
  }
  /**
   * execute action for Channel Area Preview in menu
   * @param {string} moduleName current module name
   */
  channelAreaPreview(moduleName: string) {
    this.menuActionService.channelAreaPreview(moduleName);
  }

  /**
   * execute action for change date line in menu
   * @param {string} moduleName current module name
   */
  changDateLine(moduleName: string) {
    this.menuActionService.changeDateLine(moduleName);
  }

  /**
   * execute action for switching timing area
   * @param moduleName current module name
   */
  switchArea(moduleName: string) {
    this.menuActionService.switchTimingArea(moduleName);
  }

  /**
   * handle  menu  when resize screen
   */
  handlingDisplayMenu() {
    this.isCheckWidthMenu = true;
    this.isCheckDisplay = true;
    this.isCheckSetting = true;
    this.isCheckDelivery = true;
    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'checkSettingTimetable') {
        this.isCheckSetting = <boolean>data[1];
      }
      if (data[0] == 'checkDisplayTimetable') {
        this.isCheckDisplay = <boolean>data[1];
      }
      if (data[0] == 'checkDeliveryTimetable') {
        this.isCheckDelivery = <boolean>data[1];
      }
      this.isCheckWidthMenu = this.isCheckDisplay && this.isCheckDelivery && this.isCheckSetting;
    });
  }
}
